$color-values: (
    'green': #72c466,
    'lightgreen': #c7f76e,
    'paleyellow': #fff69d,
    'yellow': #ffea00,
    'amber': #ffc800,
    'orange': #ff9100,
    'lightorange': #f8d58d,
    'pink': #e644ad,
    'lightred': #ffc5c5,
    'red': #db9342,
    'lightblue': #9be0ff,
    'blue': #597eff,
    'purple': #a76bf6,
    'light_grey': #dcdcdc,
    'grey': #9c9c9c,
    'dark_grey': #656565,
    'aqua': #14d0e1,
);

$color-fill-values: (
    'green': #dcf4d9,
    'lightgreen': #f6fde9,
    'paleyellow': #fffdeb,
    'yellow': #fffab9,
    'amber': #fff2c0,
    'orange': #ffe7c8,
    'lightorange': #fef9ee,
    'pink': #f9d1eb,
    'lightred': #fff4f4,
    'red': #fad5d5,
    'lightblue': #e9f8ff,
    'blue': #c8d4ff,
    'purple': #e7d6fd,
    'light_grey': #f9f9f9,
    'grey': #e7e7e7,
    'dark_grey': #cbcbcb,
    'aqua': #ccf5f9,
);

@mixin generate-table-cell-colors() {
    @each $color, $value in $color-fill-values {
        &--#{$color} {
            background-color: $value;
        }
    }

    @each $color, $value in $color-values {
        &--#{$color} {
            border-bottom: 2px solid $value;
        }
    }
}
