/* stylelint-disable no-invalid-position-at-import-rule */

// Include the common styles for Angular Material.
@use '@angular/cdk';

@import 'utilities';
@import '@angular/cdk/overlay-prebuilt';

@include cdk.a11y-visually-hidden();

.mat-theme-loaded-marker {
    display: none;
}

// Certain inputs are displayed on white backgroud and should be grey. These can be marked with class="grey-inputs".
.grey-inputs {
    .mat-mdc-select {
        &-trigger {
            background-color: $color-grey-light;
        }
    }

    .mat-mdc-input-element {
        background-color: $color-grey-light;
    }
}

// Tooltip

.mdc-tooltip {
    background: $color-primary;
    border-radius: 4px;
    font-size: 12px;

    &__surface {
        color: white !important;
    }
}
