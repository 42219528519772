@import 'utilities';

label {
    @include text-medium;
    @include text-bold;

    display: block;
    color: $color-primary;
    margin-bottom: 4px;
}
