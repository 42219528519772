@import 'utilities';

$t23-button-border-radius: 0;

.button-row {
    display: flex;
    margin-bottom: 30px;

    // TODO: don't use tag selector!
    ui-button {
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }
}

.modal__content .button-row {
    margin-bottom: 0;
}

button,
[type='submit'] {
    appearance: none !important;
    outline: none;
}

ui-button {
    &.theme--primary {
        .button {
            background-color: $color-primary;
            color: $color-white;
            border: 1px solid $color-primary;
        }
    }

    &.theme--secondary {
        .button {
            background-color: $color-secondary;
            color: $color-white;
            border: 1px solid $color-secondary;
        }
    }

    &.theme--tertiary {
        .button {
            background-color: $color-tertiary;
            color: $color-white;
            border: 1px solid $color-tertiary;
        }
    }

    &.theme--quaternary {
        .button {
            background-color: $color-quarternary;
            color: $color-white;
            border: 1px solid $color-quarternary;
        }
    }
}
