.mat-mdc-checkbox {
    // This is the color of the check mark.
    color: white;

    // Don't display the big touch bubble.
    &-touch-target {
        display: none;
    }

    // Don't ripple when clicked.
    .mat-ripple {
        display: none;
    }

    label {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
        width: 100%;
        margin: 0;
        vertical-align: text-bottom;
    }

    .mdc-checkbox {
        // Remove the padding for the touch bubble; Reduce the element to only the size of the checkbox to avoid placement and spacing issues.
        padding: 0 !important;

        // Without the padding, prevent the control area from growing beyond the checkbox area.
        &__native-control {
            top: 0 !important;
            left: 0 !important;
            height: 100% !important;
            width: 100% !important;

            // Color the background and border for filled (i.e. checked or indeterminate) check boxes.
            &:enabled:checked,
            &:enabled:indeterminate,
            &[data-indeterminate="true"]:enabled {
                ~ .mdc-checkbox__background {
                    border-color: $color-secondary !important;
                    background-color: $color-secondary !important;
                }
            }

            &:enabled:not(:checked, :indeterminate, [data-indeterminate="true"]) {
                ~ .mdc-checkbox__background {
                    // Insist that the border stays the same in this situation despite Material's wishes.
                    border-color: $color-primary !important;
                }
            }
        }

        // Without the padding, prevent the checkbox background from growing beyond the checkbox area.
        &__background {
            top: 0 !important;
            left: 0 !important;
            height: 100% !important;
            width: 100% !important;

            // The basic, non-disabled, non-hovering checkbox has a thin border in the primary color.
            border: 1px solid $color-primary !important;
        }

        // Color the checkbox border on hover, if enabled.
        &:hover {
            .mdc-checkbox__native-control {
                &:enabled {
                    ~ .mdc-checkbox__background {
                        border-color: $color-secondary !important;
                    }
                }
            }
        }

        // A non-disabled checkbox that has focus should have a focus border (which is thicker and darker than regular).
        // If it is not filled, the background should be the focus color.
        // An exception is made for the select-all checkbox in a table header.
        &:focus-within {
            .mdc-checkbox__native-control {
                &:enabled {
                    ~ .mdc-checkbox__background {
                        border: $t23-focus-border !important;
                    }
                }

                &:enabled:not(:checked, :indeterminate, [data-indeterminate="true"]) {
                    ~ .mdc-checkbox__background {
                        background-color: $t23-focus-background-color !important;
                    }
                }
            }
        }
    }


    // Everything should be dark grey in a disabled checkbox.
    &-disabled {
        label {
            color: $color-grey-dark;
        }

        .mdc-checkbox {
            &__background {
                border-color: $color-grey-dark !important;
            }

            &__native-control {
                &:checked,
                &:indeterminate,
                &[data-indeterminate="true"] {
                    ~ .mdc-checkbox__background {
                        background-color: $color-grey-dark !important;
                    }
                }
            }
        }
    }
}

// In a table header, a checkbox should not turn off-white when focused, or green when hovered.
.ht-dynamic-table-header-cell {
    .mat-mdc-checkbox {
        // Give a header cell checkbox the focus border instead.
        &:hover {
            .mdc-checkbox__native-control {
                &:enabled {
                    ~ .mdc-checkbox__background {
                        border: $t23-focus-border !important;
                    }
                }
            }
        }

        // The background remains transparent.
        .mdc-checkbox {
            &:focus-within {
                .mdc-checkbox__native-control {
                    &:enabled:not(:checked, :indeterminate, [data-indeterminate="true"]) {
                        ~ .mdc-checkbox__background {
                            background-color: transparent !important;
                        }
                    }
                }
            }
        }
    }
}
