$base-bottom-value: 50px;
$gap-between-box: 10px;
$t23-circle-size: 30px;
$t23-notification-padding: 20px;
$color-ui-hover: #cecece;
$color-ui-light: #cecece;
$color-ok: green;
$color-warning: red;
$color-critical: red;
$color-white: white;
$color-ui-highlight: yellow;

@import 'utilities';

.notification {
    background-color: $color-ui-hover;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
    border: 1px solid $color-ui-light;
    border-radius: 3px;
    padding: $t23-notification-padding 0;
    margin: $gap-between-box 0;
    pointer-events: all;
    position: relative;
    width: 320px;

    &__container {
        align-items: center;
        display: flex;
    }

    &__icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        height: $t23-circle-size;
        justify-content: center;
        margin: 0 $t23-notification-padding;
        position: relative;
        width: $t23-circle-size;
    }

    &__message {
        align-items: center;
        display: flex;
        font-size: 14px;
        letter-spacing: 0.2px;
        padding: 0 $t23-notification-padding;
    }

    &__highlight {
        font-weight: bold;
    }

    &__icon {
        color: $color-ui-light;
    }

    &--success {
        .notification__icon-wrapper {
            background-color: $color-ok;
        }

        .notification__message {
            color: $color-ok;
        }

    }

    &--info {
        .notification__icon-wrapper {
            background-color: $color-white;
        }

        .notification__message {
            color: $color-white;
        }

    }

    &--warning {
        .notification__icon-wrapper {
            background-color: $color-warning;
        }

        .notification__message {
            color: $color-warning;
        }

    }

    &--error {
        .notification__icon-wrapper {
            background-color: $color-critical;
        }

        .notification__message {
            color: $color-critical;

        }
    }

    &::before{
        background-color: $color-ui-highlight;
        box-shadow: 0 0 2px 0 rgb(0 0 0 / 90%);
        content: '';
        height: calc(100% - 2 * #{$t23-notification-padding * 0.5});
        left: 45px;
        position: absolute;
        top: $t23-notification-padding * 0.5;
        width: 1px;
    }
}
