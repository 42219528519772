.mat-mdc-paginator {
    font-size: 12px;

    // Page size to the left, page navigation to the right.
    .mat-mdc-paginator-container {
        justify-content: space-between;
    }

    // The default is a bit too wide for our taste.
    .mat-mdc-paginator-page-size {
        align-items: center;

        &-select {
            width: 60px;
            margin: 0 0 0 10px;
        }
    }

    .mat-mdc-select-trigger {
        background-color: transparent;
        padding: 0 10px;
    }

    .mat-mdc-paginator-page-size-label,
    .mat-mdc-paginator-range-label {
        color: $color-primary;
        font-size: 12px;
    }

    .mat-mdc-icon-button {
        background: none;
        color: $color-primary;

        &:disabled {
            opacity: .6;
        }
    }

    // No padding around the select trigger, as it cuts off the value and makes our colorization look bad.
    .mat-mdc-text-field-wrapper {
        padding: 0;
    }
}
