/*
 * Text Placeholder
 */

@mixin text-small {
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.2px;
}

@mixin text-medium {
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.2px;
}

@mixin text-large {
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.2px;
}

@mixin paragraph {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: normal;
    font-size: 13px;
}

@mixin text-bold {
    font-weight: bold;
}

/*
 * Heading Placeholder
 */
@mixin heading-small {
    font-family: 'Metronic Pro', sans-serif;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.3px;
}

@mixin heading-medium {
    font-family: 'Metronic Pro', sans-serif;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.2px;
}

@mixin heading-large {
    font-family: 'Metronic Pro', sans-serif;
    font-weight: normal;
    font-size: 24px;
}

.page-title {
    color: $color-primary;
    margin: 15px 15px 15px 10px;
    font-weight: normal;
}

.page-subtitle {
    color: $color-primary;
    margin-top: 30px;
    margin-bottom: 21px;
    font-weight: normal;
}
