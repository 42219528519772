@import 'utilities';

$t23-input-padding: 0 10px;
$t23-input-height: 37px;
$t23-input-border: none;

$t23-input-label-margin: 4px;
$t23-input-label-color: $color-primary;
$t23-input-label-size: 12px;

.input {
    .input__wrapper {
        position: relative;

        ui-icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
}

input,
input.mat-mdc-input-element {
    @include text-medium;

    display: block;
    box-sizing: border-box;
    width: 100%;
    height: $t23-input-height;
    padding: $t23-input-padding;
    border: $t23-input-border;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $color-white;
    color: $t23-input-label-color;
    line-height: $t23-input-height;

    &::placeholder {
        color: $color-grey-dark;
        font-style: italic;
    }

    &:disabled {
        color: $color-grey-dark;
    }

    &.disabled {
        color: $color-grey-dark;
        cursor: default;
    }
}

.grey-inputs {
    input {
        background-color: $color-grey-light;
    }
}

input {
    outline: none;
}

form.ng-invalid.ng-touched .invalid {
    color: $color-fail !important;

    input::placeholder {
        color: $color-fail !important;
    }
}
