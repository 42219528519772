.icon svg {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;

    * {
        stroke: currentcolor;
    }
}
