@import 'primeicons/primeicons.css';


// changing font size to match other inputs
html .p-inputtext {
    font-size: 0.75rem;
}

html:root {
    // standard theme changes
    --p-form-field-border-radius: 0;
    --p-form-field-border-color: transparent;
    --p-form-field-color: #{ $color-primary };
    --p-primary-color: #{ $color-secondary };

    // datepicker
    --p-datepicker-dropdown-background: #{ $color-secondary };
    --p-datepicker-dropdown-hover-background: #{ lighten($color-secondary, 15%) };
    --p-datepicker-dropdown-active-background: #{ lighten($color-secondary, 5%) };
    --p-datepicker-dropdown-color: #{ $color-white };
    --p-datepicker-dropdown-hover-color: #{ $color-white };
    --p-datepicker-dropdown-active-color: #{ $color-white };
}
