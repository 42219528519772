@import 'utilities';

// NOTE: Do not add styles here
// All styles listed here should be refactored at some point.

.no-data {
    margin-left: 10px;
    color: $color-grey-dark;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.2px;

    ui-icon {
        vertical-align: text-bottom;
        color: $color-grey-dark;
    }
}

textarea {
    resize: none;
}

.widget--card ht-note .ht-note {
    box-shadow: none;
    border: 1px solid $color-grey-light;
}
