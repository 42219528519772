@import '../variables/typography';
@import '../variables/colors';

html {
    font-family: 'Noto Sans', sans-serif;
    min-width: 1024px;
    height: 100%;
    background-color: $color-grey-light;

    body {
        height: 100%;
        margin: 0;
        overflow: hidden;

        app-root {
            height: 100%;
            display: block;
        }
    }

    /**
     * Fix fonts that render as bold in Firefox
     */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
