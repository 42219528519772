.widget {
    display: block;

    &__wrapper {
        margin-bottom: 30px;

        &--compressed {
            margin-bottom: 10px;

            .widget--card {
                padding: 12px;
            }
        }
    }

    &--card {
        padding: 30px;
        box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
        overflow: visible;
        background: #fff;
        width: fit-content;

        .widget--card {
            padding: 0;
            box-shadow: none;
        }
    }
}

.modal__content {
    .widget--card {
        padding: 0;
        box-shadow: none;
        min-width: 500px;
    }
}
