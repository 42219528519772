@font-face {
    font-family: 'Noto Sans';
    src: url('/../assets/fonts/NotoSans.woff2') format('woff2'),
        url('/../assets/fonts/NotoSans.woff') format('woff'),
        url('/../assets/fonts/NotoSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('/../assets/fonts/NotoSans-Bold.woff2') format('woff2'),
        url('/../assets/fonts/NotoSans-Bold.woff') format('woff'),
        url('/../assets/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('/../assets/fonts/NotoSans-SemiBold.woff2') format('woff2'),
        url('/../assets/fonts/NotoSans-SemiBold.woff') format('woff'),
        url('/../assets/fonts/NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('/../assets/fonts/NotoSans-SemiBoldItalic.woff2') format('woff2'),
        url('/../assets/fonts/NotoSans-SemiBoldItalic.woff') format('woff'),
        url('/../assets/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('/../assets/fonts/NotoSans-BoldItalic.woff2') format('woff2'),
        url('/../assets/fonts/NotoSans-BoldItalic.woff') format('woff'),
        url('/../assets/fonts/NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Metronic Pro';
    src: url('/../assets/fonts/MetronicPro.woff2') format('woff2'),
        url('/../assets/fonts/MetronicPro.woff') format('woff'),
        url('/../assets/fonts/MetronicPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metronic Pro';
    src: url('/../assets/fonts/MetronicPro-Bold.woff2') format('woff2'),
        url('/../assets/fonts/MetronicPro-Bold.woff') format('woff'),
        url('/../assets/fonts/MetronicPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('/../assets/fonts/RobotoCondensed.woff2') format('woff2'),
        url('/../assets/fonts/RobotoCondensed.woff') format('woff'),
        url('/../assets/fonts/RobotoCondensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$font-small: 12px;
