@import 'utilities';

.ht-table-cell {
    outline: 1px solid $color-grey-light;
    background-color: $color-white;
    font-size: 12px;
    color: $color-primary;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;

    &.readonly {
        padding: 7px 10px;
    }

    &__color {
        @include generate-table-cell-colors();
    }
}
